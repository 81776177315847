import React from "react";
import Header from "../../components/Header/Header"
import SearchResultPage from "../../templates/search-results-template";
import Footer from "../../components/Footer/Footer"
import {
    parseSearchUrl,
    savedSearchParams,
    propertyH1
} from "../../components/common/site/utils";

const SearchResultRent = (props) => {

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location
    const searchParams = parseSearchUrl("lettings", fullpath);

    return (
        <div className="search-results">
            <Header />
            <SearchResultPage locationname="/property-to-rent/" pcategorytype="residential" ptype="lettings" ptypetag="to-rent" pstatustype={["To Let"]} fullpathone={fullpathone} fullpathname={fullpath} location={location}/>
            {/* <Footer popular_search="results" /> */}
            
        </div>
    );
};

export default SearchResultRent;
